import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from './container'
import * as styles from './products-preview.module.css'

const ProductsPreview = ({ products }) => {
  if (!products) return null
  if (!Array.isArray(products)) return null

  var formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
  });

  if (products.length > 1) {
      var list_style = styles.productList
  } else {
      list_style = styles.singleProduct
  }

  return (
    <Container>
      <ul className={list_style}>
        {products.map((product) => {
          return (
            <li key={product.slug}>
              <Link to={`/product/${product.slug}`} className={styles.link}>
                <GatsbyImage alt="" image={product.mainImage.gatsbyImageData} />
                <h2 className={styles.title}>{product.name}</h2>
                <h3 className={styles.price}>{formatter.format(product.price)}</h3>
              </Link>
            </li>
          )
        })}
      </ul>
    </Container>
  )
}

export default ProductsPreview
