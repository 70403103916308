import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import ProductsPreview from '../components/products-preview'


class ProductIndex extends React.Component {
  render() {
    const products = get(this, 'props.data.allContentfulProduct.nodes')
    const [siteInfo] = get(this, 'props.data.allContentfulSiteInfo.nodes')

    return (
      <Layout location={this.props.location}>
        <Seo title="Shop" />
        <Hero image={siteInfo.frontPageImage.gatsbyImageData}
              title="Webshop" 
              content="Hoewel Makers Inc. officieel geen webshop meer is, valt er nog wél wat te shoppen!"
          />
        <ProductsPreview products={products} />
      </Layout>
    )
  }
}

export default ProductIndex

export const pageQuery = graphql`
  query ProductQuery {
    allContentfulProduct {
        nodes {
          name
          price
          slug
          mainImage {
            gatsbyImageData
          }
          additionalImages {
            gatsbyImageData
          }
          description {
            childMarkdownRemark {
              excerpt
              html
            }
          }
        }
      }
    allContentfulSiteInfo(
      filter: { contentful_id: { eq: "4aHS1S4Eur31mTmAgShqeQ" } }
    ) {
      nodes {
        frontPageImage {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            width: 1180
            quality: 100
          )
        }
      }
    } 
  }  
`
